<template>
	<div class="tags fr-b-c" v-if="showTags" ref="tag">
		<!-- <i class="el-icon-caret-left tag-linkage" @click="hoverScrool()"></i> -->
		<div class="tag-box" ref="tagbox">
			<ul ref="tagboxUl" :style="{transform:`translate(-${left}px)`}">
				<li class="tags-li" v-for="(item,index) in tagsList" :class="{'active': isActive(item.path)}" :key="index" ref="tagsLi">
					<router-link :to="item.path" class="tags-li-title">
						{{item.title}}
					</router-link>
					<span class="tags-li-icon" v-if="index!=0" @click="closeTags(index)"><i class="el-icon-close"></i></span>
				</li>
			</ul>
		</div>

		<!-- <i class="el-icon-caret-right tag-linkage" @click="hoverScrool(9999)"></i> -->
		<div class="tags-close-box fr-s-c" ref="tagclose">
			<el-dropdown v-if="hidList.length" @command="switchRouter">
				<el-button size="mini" class="more-btn"><i class="el-icon-more"></i></el-button>
				<el-dropdown-menu size="small" slot="dropdown" >
					<el-dropdown-item v-for="(i,v) in hidList" :key="v" :command="i.path" >
						{{i.title}}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-button @click="resetPage" size="mini" class="refresh-btn"><i class="el-icon-refresh-right"></i></el-button>
			<el-dropdown @command="handleTags">
				<el-button size="mini" class="close-btn"><i class="el-icon-arrow-down"></i></el-button>
				<el-dropdown-menu size="small" slot="dropdown">
					<el-dropdown-item command="other">关闭其他</el-dropdown-item>
					<el-dropdown-item command="all">关闭所有</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-button size="mini" class="full-screen-btn" @click="handleFullScreen">
				<img src="../../../assets/img/nofull.png" v-if="fullscreen" />
				<img src="../../../assets/img/full.png" v-else />
			</el-button>
		</div>
	</div>
</template>

<script>
import bus from '../bus';
export default {
	data () {
		return {
			tagsList: this.initRoute(),
			left: 0,
			fullscreen: false,
			hidList: [],
			time: null
		}
	},
	mounted () {
		this.$nextTick(() => {
			window.addEventListener("resize", () => {
				// console.log("111")
				this.getWiden()
			})
		});
	},
	methods: {
		getWiden () {
			this.tagFid()
			//防抖语句
			if (this.time) {
				clearTimeout(this.time);
			}
			this.time = setTimeout(() => {
				this.setHidTag()
			}, 500)
		},
		tagFid () {

			if (this.$refs.tagboxUl && this.$refs.tagbox) {
				let ulWidth = this.$refs.tagboxUl.clientWidth
				let tagboxWidth = this.$refs.tagbox.clientWidth

				if (ulWidth > tagboxWidth) {
					this.left = ulWidth - tagboxWidth
				} else {
					this.left = 0
				}
			}
		},
		//计算隐藏标签--需要加防抖
		setHidTag () {
			let tagid = -1;
			let countWidth = this.$refs.tagbox.clientWidth
			this.$refs.tagsLi.forEach((item, index) => {
				const id = this.$refs.tagsLi.length - 1 - index;
				const rw = this.$refs.tagsLi[id].offsetWidth;
				countWidth -= rw;
				if (countWidth < 0 && tagid == -1) tagid = id;
			})
			this.hidList = this.tagsList.slice(0, tagid + 1)
			if (this.hidList.length) {
				this.$nextTick(() => {
					this.tagFid()
				})
			}
		},
		switchRouter (path) {
					this.$router.push(path)
		},
		resetPage () {
			this.$router.go(0)
		},
		initRoute () {
			const route = this.$router.options.routes[0].children[0]
			return [{
				title: route.meta.title,
				path: route.path,
				name: ''
			}]
		},
		hoverScrool (len = 0) {
			this.$refs.tagbox.scrollTo({
				left: len,
				behavior: 'smooth'
			})
		},
		isActive (path) {
			return path === this.$route.fullPath;
		},
		// 关闭单个标签
		closeTags (index) {
			const delItem = this.tagsList.splice(index, 1)[0];
			const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
			if (item) {
				delItem.path === this.$route.fullPath && this.$router.push(item.path);
			} else {
				this.$router.push('/');
			}
		},
		// 关闭全部标签
		closeAll () {
			this.tagsList = [];
			this.$router.push('/');
			this.$nextTick(() => {
				this.getWiden()
			})
		},
		// 关闭其他标签
		closeOther () {
			const curItem = this.tagsList.filter(item => {
				return item.path === this.$route.fullPath || item.path.indexOf('home')>-1;
			})
			this.tagsList = curItem;
			this.$nextTick(() => {
				this.getWiden()
			})
		},
		// 设置标签
		setTags (route) {
			const isExist = this.tagsList.some(item => {
				return item.path === route.fullPath;
			})
			if (!isExist) {
				// if (this.tagsList.length >= 8) {
				//     this.tagsList.shift();
				// }
				this.tagsList.push({
					title: route.meta.title,
					path: route.fullPath,
					name: route.matched[1].components.default.name
				})
			} else {
				const index = this.hidList.findIndex(item => {
					return item.path === route.fullPath;
				})
				if (index > -1) {
					this.tagsList.splice(index, 1);
					this.tagsList.push({
						title: route.meta.title,
						path: route.fullPath,
						name: route.matched[1].components.default.name
					})
				}
			}

		},
		handleTags (command) {
			command === 'other' ? this.closeOther() : this.closeAll();
		},

		// 全屏事件
		handleFullScreen () {
			let element = document.documentElement;
			if (this.fullscreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					// IE11
					element.msRequestFullscreen();
				}
			}
			this.fullscreen = !this.fullscreen;
		},
	},
	computed: {
		showTags () {
			return this.tagsList.length > 0;
		}
	},
	watch: {
		$route (newValue, oldValue) {
			this.setTags(newValue);
			// console.log(newValue,'111111')
			this.$nextTick(() => {
				this.getWiden()
			})

		}
	},
	created () {
		this.setTags(this.$route);
		// 监听关闭当前页面的标签页
		bus.$on('close_current_tags', () => {
			for (let i = 0, len = this.tagsList.length; i < len; i++) {
				const item = this.tagsList[i];
				if (item.path === this.$route.fullPath) {
					if (i < len - 1) {
						this.$router.push(this.tagsList[i + 1].path);
					} else if (i > 0) {
						this.$router.push(this.tagsList[i - 1].path);
					} else {
						this.$router.push('/');
					}
					this.tagsList.splice(i, 1);
					break;
				}
			}
		})

	},
}
</script>


<style>
/* .tags .tag-box {
		scrollbar-width: none;
		/* firefox */
/* -ms-overflow-style: none; */
/* IE 10+ */
/* } */

.tags {
  position: relative;
  height: 32px;
  overflow: hidden;
  /* padding: 0 16px; */
  padding-left: 5px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex: none;
}

.tags .tag-box {
  /* width: 100%; */
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  transform: translate(0);
}

.tags .tag-box ul {
  position: absolute;
  display: flex;
  box-sizing: border-box;
  transition: transform 0.3s;
}

.tags .tag-box::-webkit-scrollbar {
  display: none;
}

.tags-li {
  margin-right: 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 0 5px 0 10px;
  vertical-align: middle;
  color: #323232;
  /* -webkit-transition: all 0.1s ease-in;
		-moz-transition: all 0.1s ease-in;
		transition: all 0.1s ease-in; */
  display: inline-block;
}

.tags-li-title {
  float: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #323232;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-li.active {
  background-color: #0960bd;
  color: #fff;
}

.tags-li:not(.active) span {
  display: none;
}

.tags-li span:hover {
  transform: scale(1.4);
}

.tags-li:not(.active):hover {
  background: #fff;
}

.tags-li:not(.active):hover span {
  display: inline-block !important;
  color: #0960bd;
}

.tags-li:not(.active):hover .tags-li-title {
  color: #0960bd;
}

.tags-close-box {
  /* position: absolute;
		right: 0;
		top: 0; */
  box-sizing: border-box;
  /* padding-top: 1px;
		text-align: center;
		width: 110px;
		height: 30px; */
  height: 100%;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  flex: none;
}

/* .tag-linkage {
		width: 30px;
		height: 30px;
		background: #ffffff;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
	} */
.tags-close-box .el-dropdown,
.tags-close-box button {
  padding: 0;
  border: 0;
  height: 100%;
  background-color: #fff;
  border-radius: 0;
}

.tags-close-box .more-btn,
.tags-close-box .refresh-btn,
.tags-close-box .close-btn {
  width: 32px;
  height: 100%;
  border-right: 1px solid #eee;
}

.tags-close-box .full-screen-btn {
  width: 32px;
  height: 100%;
  border-right: 0;
}

.full-screen-btn img {
  width: 15px;
  height: 12px;
}

.tags-close-box button i {
  color: #646464;
  font-size: 14px;
}

.tags-close-box button:hover {
  background-color: #fff;
}

.tags-close-box button:hover i {
  color: #000;
  font-weight: 600;
}
</style>
