var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { ref: "maindiv" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-around",
            "flex-direction": "row",
          },
        },
        [
          _vm.roleName != "超级用户角色" &&
          _vm.roleName != "管理员" &&
          _vm.roleName != "大管家" &&
          _vm.roleName != "客服"
            ? _c("div", { staticClass: "list-content" }, [
                _c("h2", [_vm._v("Welcome")]),
              ])
            : _c("div", { staticClass: "dataContent" }, [
                _vm.homeMoudle
                  ? _c("div", { staticClass: "list-content" }, [
                      _c("h2", [_vm._v("统计")]),
                      _c("ul", { staticClass: "data-list" }, [
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(1)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.homeMoudle.pendingConsultationNumber
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待处理用户咨询"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(2)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  _vm._s(_vm.homeMoudle.pendingComplaintNumber)
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待处理动态投诉"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(3)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.homeMoudle.toAuditCircleNumber)
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待审核圈子"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(4)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  _vm._s(_vm.homeMoudle.toSendOrderNumber)
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待发货订单"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(5)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  _vm._s(_vm.homeMoudle.toStayOrderNumber)
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待自提订单"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            {
                              staticClass: "data-item",
                              on: {
                                click: function ($event) {
                                  return _vm.homeClickHandle(6)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "menuitem_num" }, [
                                _vm._v(
                                  _vm._s(_vm.homeMoudle.waitReplyNumber || 0)
                                ),
                              ]),
                              _c("div", { staticClass: "menuitem_name" }, [
                                _vm._v("待回复任务"),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }