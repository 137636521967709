<template>
  <div class="sidebar">
    <div class="side-logo">
      <img class="logo" src="../../../assets/img/logo.png" alt="" />
      <div class="title-name" v-if="!sidebar">
        <span class="text-line-1">医可达</span>
      </div>
    </div>
    <div class="side-menu">
      <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="sidebar" unique-opened active-text-color="#FFFFFF" text-color="#A2AAB0" router>
        <el-submenu v-for="(menu, i) in menulist" :key="menu.id" :index="i + ''">
          <template slot="title">
            <i :class="menu.attributes.icon"></i>
            <span class="menu-text">{{ menu.text }}</span>
          </template>
          <el-menu-item-group style="background-color:#0C2135;">
            <el-menu-item v-for="submenu in menu.children" :key="submenu.id" :index="submenu.attributes.url" :route="{ path: submenu.attributes.url }">
              <template slot="title">
                <span>{{ submenu.text }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import adminManager from "@/request/adminManager.js";
import { mapState } from 'vuex'
import bus from '../bus';
export default {
  data () {
    return {
      collapse: false,
      // menulist: [],
    };
  },
  computed: {
    onRoutes () {
      return this.$route.path;
    },
    ...mapState({
      sidebar: state => state.app.sidebar,
      menulist: state => state.app.treeList,
    }),
  },
  created () {
    this.getMenuList();
    //监听刷新菜单栏
    bus.$on('refreshTabTree', () => {
      this.getMenuList();
    })
  },
  methods: {
    async getMenuList () {
      let data = await adminManager.sysMenuTreeList()
      if (data.code == 200) {

        // data.data.reduce((arr,i)=>[...arr,...i.children],[]).map(o=>{
        //   console.log({ path: `/${o.attributes.url}`, component:0, meta: { title: o.text } })
        //   this.$router.addRoute({ path: `/${o.attributes.url}`, component:()=>import('@/components/page/system/main/Main.vue'), meta: { title: o.text } })
        //   // return { path: `/${o.attributes.url}`, component:()=>import('@/components/page/system/main/Main.vue'), meta: { title: o.text } }
        // })

        // this.$store.commit('app/appAddTree', data.data)


        var array = []
          var arr = data.data.filter((obj) => {
            if (obj.attributes.url != '/home') {
              return true
            } else {
              array.push(obj)
              return false
            }
          })
          this.$store.commit('app/appAddDaPingTree', array)
          this.$store.commit('app/appAddTree', arr)
      } 
    }
  },
};
</script>

<style scoped>
.sidebar {
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #001529;
}
.side-menu {
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 73px);
  scrollbar-width: none;   /* firefox */
  -ms-overflow-style: none;  /* IE 10+ */ 
}
.side-menu::-webkit-scrollbar {
  width: 0px;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.sidebar > ul {
  height: 100%;
}
.side-logo {
  height: 73px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
}
.title-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  line-height: 19px;
}
</style>
