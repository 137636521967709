var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "side-logo" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../../assets/img/logo.png"), alt: "" },
      }),
      !_vm.sidebar
        ? _c("div", { staticClass: "title-name" }, [
            _c("span", { staticClass: "text-line-1" }, [_vm._v("医可达")]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "side-menu" },
      [
        _c(
          "el-menu",
          {
            staticClass: "sidebar-el-menu",
            attrs: {
              "default-active": _vm.onRoutes,
              collapse: _vm.sidebar,
              "unique-opened": "",
              "active-text-color": "#FFFFFF",
              "text-color": "#A2AAB0",
              router: "",
            },
          },
          _vm._l(_vm.menulist, function (menu, i) {
            return _c(
              "el-submenu",
              { key: menu.id, attrs: { index: i + "" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { class: menu.attributes.icon }),
                  _c("span", { staticClass: "menu-text" }, [
                    _vm._v(_vm._s(menu.text)),
                  ]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticStyle: { "background-color": "#0C2135" } },
                  _vm._l(menu.children, function (submenu) {
                    return _c(
                      "el-menu-item",
                      {
                        key: submenu.id,
                        attrs: {
                          index: submenu.attributes.url,
                          route: { path: submenu.attributes.url },
                        },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v(_vm._s(submenu.text))]),
                        ]),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              2
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }