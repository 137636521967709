<template>
  <div class="header">
    <div class="header-left fr-s-c">
      <!-- 折叠按钮 -->
      <div class="collapse-btn" @click="collapseChage">
        <i v-if="!sidebar" class="el-icon-s-fold"></i>
        <i v-else class="el-icon-s-unfold"></i>
      </div>
      <div class="collapse-btn ">
        <i class="el-icon-full-screen" @click="goToData"></i>
      </div>
    </div>
    <div class="header-right">
      <div class="header-user-con">

        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
					<el-tooltip effect="dark" :content="`消息中心`" placement="bottom">
						<router-link to="/getsystemmessagelist">
							<i class="el-icon-bell" style=" color: #333333;"></i>
						</router-link>
					</el-tooltip>
					<span class="btn-bell-badge" v-if="message"></span>
				</div> -->
        <el-tooltip effect="dark" :content="`锁屏`" placement="bottom">
          <div class="btn-bell" @click="toLockScreen">
            <i class="el-icon-lock" style=" color: #333333;"></i>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" :content="`退出`" placement="bottom">
          <div class="btn-bell" @click="logOut">
            <i class="el-icon-switch-button" style=" color: #333333;"></i>
          </div>
        </el-tooltip>
        <div class="line"></div>
        <!-- 用户头像 -->
        <div class="user-avator">
          <img :src="adminHeadImg" />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{userName}}
            <!-- <i class="el-icon-arrow-down"></i> -->
            <i class="el-icon-arrow-down" style="font-size: 14px; padding-left: 10px;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="editpassword">修改密码</el-dropdown-item>
            <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="lockScreen" v-if="isLockScreen">
      <lock-screen></lock-screen>
    </div>
    <div class="lockScreen" v-if="isEditPwd">
      <edit-pwd></edit-pwd>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import editPwd from '../subgroup/EditUserPwdInfo.vue'
import lockScreen from '../subgroup/LockScreen.vue'
import adminManager from '@/request/adminManager.js'
import loginManager from '@/request/loginManager.js'
import store from '@/store'
export default {
  components: {
    editPwd,
    lockScreen,
  },
  data() {
    return {
      branchList: [], //公司信息
      // branchCode:'',   //公司code
      collapse: false,
      adminName: 'admin',
      message: 2,
      adminHeadImg: require('../../../assets/img/head.png'),
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      isLockScreen: (state) => state.app.isLockScreen,
      isEditPwd: (state) => state.app.isEditPwd,
      userName: (state) => state.admin.userName,
      accessToken: (state) => state.admin.accessToken,
    }),
  },
  created() {
    // this.getUserInfo()
  },
  methods: {
    goToData(){
      window.open('/dataIndex')
    },
    // async getUserInfo() {
    // 	let {
    // 		code,
    // 		result
    // 	} = await adminManager.getLoginAdminInfo()
    // 	console.log('登录用户信息', result)
    // 	if (code == 100) {
    // 		this.adminName = result.adminName;
    // 		this.adminHeadImg = result.adminHeadImg;
    // 	}
    // },
    editPassword() {
      this.$store.dispatch('app/toggleIsEditPwd', true)
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == 'loginout') {
        this.logOut()
      }
      if (command == 'editpassword') {
        this.editPassword()
      }
    },
    //
    async logOut() {
      let { code, result } = await loginManager.logout({
        access_token: store.getters.token,
      })
      console.log(result)
      if (code == 200) {
        // this.$store.dispatch('admin/removeAdminID')
        this.$store.dispatch('admin/removeToken')
        this.$store.commit('admin/SET_CODE', '')
        this.$router.replace('/login')
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.$store.dispatch('app/toggleSideBar')
    },

    toLockScreen() {
      this.$store.dispatch('app/toggleIsLockScreen', true)
    },
  },
  mounted() {
    if (document.body.clientWidth < 1200) {
      this.collapseChage()
    }
  },
}
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  font-size: 18px;
  color: #333333;
  background-color: #fff;
}

.header-left {
  float: left;
}

.collapse-btn {
  /* float: left; */
  padding-left: 20px;
  cursor: pointer;
  line-height: 46px;
  font-size: 20px;
}

.header .logo {
  float: left;
  width: 250px;
  line-height: 46px;
}

.header-right {
  float: right;
  padding-right: 10px;
}

.header-user-con {
  display: flex;
  height: 46px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 5px;
  line-height: 30px;
}

.btn-bell-badge {
  position: absolute;
  right: 8px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #333333;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 13px;
}

.user-avator img {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #333333;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}

.line {
  width: 1px;
  height: 27px;
  background-color: #eeeeee;
  margin: 0 5px;
}

.lockScreen {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
}
.change-icon {
  width: 18px;
  height: 18px;
  margin-top: -3px;
}
</style>
