<template>
  <div class="wrapper">
    <v-sidebar></v-sidebar>
    <div class="content-box" :class="{ 'content-collapse': sidebar }">
      <v-head></v-head>
      <v-tags></v-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <router-view></router-view>
        </transition>
        <el-backtop target=".content"></el-backtop>
      </div>

    </div>
    <!-- 实名认证 -->
    <div class="lockScreen" v-if="dialogRealNameAuthVisible">
      <RealNameAuth @hide="dialogRealNameAuthVisible = false"></RealNameAuth>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vHead from './Header.vue';
import vSidebar from './Sidebar.vue';
import vTags from './Tags.vue';
import RealNameAuth from '@/components/page/agent/sub/RealNameAuth.vue'
import systemSetManager from '@/request/systemSetManager.js'
export default {

  data() {
    return {
      tagsList: [],
      // 
      dialogRealNameAuthVisible: false,
      roleName: '',
    };
  },
  created() {
    this.roleName = this.$store.getters.roleName;
    if (this.roleName == '代理商') {
      this.getSearchSmrzFlag()
    }
  },
  methods: {
    async getSearchSmrzFlag() {
      try {
        let data = await systemSetManager.searchSmrzFlag();
        if (data.code == 200) {
          // smrzFlag 实名验证标识(0否 ,1是)
          if (data.data.smrzFlag == 0) {
            this.dialogRealNameAuthVisible = true
          }
        }
      } catch (error) {
        //
      }
    },
  },
  components: {
    vHead,
    vSidebar,
    vTags,
    RealNameAuth,
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar
    }),
  }
};
</script>
<style scoped>
.lockScreen {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
</style>
