var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header-left fr-s-c" }, [
      _c(
        "div",
        { staticClass: "collapse-btn", on: { click: _vm.collapseChage } },
        [
          !_vm.sidebar
            ? _c("i", { staticClass: "el-icon-s-fold" })
            : _c("i", { staticClass: "el-icon-s-unfold" }),
        ]
      ),
      _c("div", { staticClass: "collapse-btn" }, [
        _c("i", {
          staticClass: "el-icon-full-screen",
          on: { click: _vm.goToData },
        }),
      ]),
    ]),
    _c("div", { staticClass: "header-right" }, [
      _c(
        "div",
        { staticClass: "header-user-con" },
        [
          _c(
            "el-tooltip",
            { attrs: { effect: "dark", content: `锁屏`, placement: "bottom" } },
            [
              _c(
                "div",
                { staticClass: "btn-bell", on: { click: _vm.toLockScreen } },
                [
                  _c("i", {
                    staticClass: "el-icon-lock",
                    staticStyle: { color: "#333333" },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            { attrs: { effect: "dark", content: `退出`, placement: "bottom" } },
            [
              _c(
                "div",
                { staticClass: "btn-bell", on: { click: _vm.logOut } },
                [
                  _c("i", {
                    staticClass: "el-icon-switch-button",
                    staticStyle: { color: "#333333" },
                  }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "user-avator" }, [
            _c("img", { attrs: { src: _vm.adminHeadImg } }),
          ]),
          _c(
            "el-dropdown",
            {
              staticClass: "user-name",
              attrs: { trigger: "click" },
              on: { command: _vm.handleCommand },
            },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v(" " + _vm._s(_vm.userName) + " "),
                _c("i", {
                  staticClass: "el-icon-arrow-down",
                  staticStyle: { "font-size": "14px", "padding-left": "10px" },
                }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "editpassword" } },
                    [_vm._v("修改密码")]
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { divided: "", command: "loginout" } },
                    [_vm._v("退出登录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm.isLockScreen
      ? _c("div", { staticClass: "lockScreen" }, [_c("lock-screen")], 1)
      : _vm._e(),
    _vm.isEditPwd
      ? _c("div", { staticClass: "lockScreen" }, [_c("edit-pwd")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }