<template>
  <div class="login-bg fl-c fr-c">
	  <div class="lockscreen-wrap fl-c">
		  <div class="ibox-content">
		  	<el-form label-width="100px" ref="editPwdModel" :model="editPwdModel" :rules="editPwdRules">
		  	  <el-form-item label="新密码" prop="pwdNew">
		  	    <el-input size="medium" type="password" v-model="editPwdModel.pwdNew" placeholder="请输入新密码" show-password></el-input>
		  	  </el-form-item>
		  	  <el-form-item label="确认密码" prop="repeat_login_pwd">
		  	    <el-input size="medium" type="password" v-model="editPwdModel.repeat_login_pwd" placeholder="请输入确认密码" show-password></el-input>
		  	  </el-form-item>
		  	</el-form>
		  	<div slot="footer" class="dialog-footer">
		  	  <el-button @click="clickEditPwdCancel">取 消</el-button>
		  	  <el-button type="primary" :loading="editPwdLoading" @click="clickEditPwdConfirm">确定</el-button>
		  	</div>
		  </div>
	  </div>
  		
  
  	</div>
</template>



<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import adminManager from "@/request/adminManager.js";
export default {
  data () {
    // 这里存放数据
    var validateAdminPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
        if (regex.test(value)) {
          callback();
          if (this.editPwdModel.repeat_login_pwd !== '') {
            this.$refs.editPwdModel.validateField('repeat_login_pwd');
          }
        } else {
          callback(new Error("请输入6到20位的数字字母混合密码"));
        }
      }
    };
    var validateRepeatPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else {
        var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
        if (regex.test(value)) {
          if (value !== this.editPwdModel.pwdNew) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入6到20位的数字字母混合密码"));
        }
      }
    };
    return {
      editPwdModel: {
        // admin_id:this.admin_id,    //后台用户ID
        pwdNew: '',    //密码
        repeat_login_pwd: '',    //确认密码
      },
      editPwdRules: {
        pwdNew: [{ required: true, message: "请输入新密码", trigger: "blur" }, { validator: validateAdminPwd, trigger: "blur" }],
        repeat_login_pwd: [{ required: true, message: "请输入确认密码", trigger: "blur" }, { validator: validateRepeatPwd, trigger: "blur" }],
      },
      editPwdLoading: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  // 方法集合
  methods: {
    // 点击修改密码---取消
    clickEditPwdCancel () {
      this.$store.dispatch("app/toggleIsEditPwd",false)
    },
    // 点击修改密码---确认
    async clickEditPwdConfirm () {
      this.$refs.editPwdModel.validate(async (valid) => {
        if (valid) {
          try {
            let { code, message } = await adminManager.resetPassword(this.editPwdModel)
            if (code === 200) {
              this.$message.success('修改成功');
              this.$store.dispatch("app/toggleIsEditPwd",false)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped>
	.login-bg {
		background: rgba(0,0,0,.3);
		background-size: cover;
		height: 100%;
	}
	
	.lockscreen-wrap {
		position: relative;
		height: 100%;
		width: 100%;
	}
	
	.ibox-content {
		background-color: #fff;
		padding: 25px 50px 30px 50px;
		clear: both;
		border-radius: 6px;
		width: 300px;
	}
	
	.full-width {
		width: 100% !important;
	}
.dialog-footer {
  padding: 50px 0px 20px;
  box-sizing: border-box;
}
</style>
