var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showTags
    ? _c("div", { ref: "tag", staticClass: "tags fr-b-c" }, [
        _c("div", { ref: "tagbox", staticClass: "tag-box" }, [
          _c(
            "ul",
            {
              ref: "tagboxUl",
              style: { transform: `translate(-${_vm.left}px)` },
            },
            _vm._l(_vm.tagsList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  ref: "tagsLi",
                  refInFor: true,
                  staticClass: "tags-li",
                  class: { active: _vm.isActive(item.path) },
                },
                [
                  _c(
                    "router-link",
                    { staticClass: "tags-li-title", attrs: { to: item.path } },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  ),
                  index != 0
                    ? _c(
                        "span",
                        {
                          staticClass: "tags-li-icon",
                          on: {
                            click: function ($event) {
                              return _vm.closeTags(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { ref: "tagclose", staticClass: "tags-close-box fr-s-c" },
          [
            _vm.hidList.length
              ? _c(
                  "el-dropdown",
                  { on: { command: _vm.switchRouter } },
                  [
                    _c(
                      "el-button",
                      { staticClass: "more-btn", attrs: { size: "mini" } },
                      [_c("i", { staticClass: "el-icon-more" })]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        attrs: { slot: "dropdown", size: "small" },
                        slot: "dropdown",
                      },
                      _vm._l(_vm.hidList, function (i, v) {
                        return _c(
                          "el-dropdown-item",
                          { key: v, attrs: { command: i.path } },
                          [_vm._v(" " + _vm._s(i.title) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "refresh-btn",
                attrs: { size: "mini" },
                on: { click: _vm.resetPage },
              },
              [_c("i", { staticClass: "el-icon-refresh-right" })]
            ),
            _c(
              "el-dropdown",
              { on: { command: _vm.handleTags } },
              [
                _c(
                  "el-button",
                  { staticClass: "close-btn", attrs: { size: "mini" } },
                  [_c("i", { staticClass: "el-icon-arrow-down" })]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    attrs: { slot: "dropdown", size: "small" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", { attrs: { command: "other" } }, [
                      _vm._v("关闭其他"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "all" } }, [
                      _vm._v("关闭所有"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "full-screen-btn",
                attrs: { size: "mini" },
                on: { click: _vm.handleFullScreen },
              },
              [
                _vm.fullscreen
                  ? _c("img", {
                      attrs: { src: require("../../../assets/img/nofull.png") },
                    })
                  : _c("img", {
                      attrs: { src: require("../../../assets/img/full.png") },
                    }),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }