<template>
  <div class="container">
    <div ref="maindiv">
      <div style="display: flex; justify-content: space-around; flex-direction: row;">
        <div v-if="roleName != '超级用户角色' && roleName != '管理员' && roleName != '大管家' && roleName != '客服'" class="list-content">
          <h2>Welcome</h2>
        </div>
        <!-- <div v-if="!permission.homeList" class="list-content">
					<h2>Welcome</h2>
				</div> -->
        <div v-else class="dataContent">
          <div class="list-content" v-if="homeMoudle">
            <h2>统计</h2>
            <ul class="data-list">
              <li>
                <div class="data-item" @click="homeClickHandle(1)">
                  <div class="menuitem_num">{{ homeMoudle.pendingConsultationNumber }}</div>
                  <div class="menuitem_name">待处理用户咨询</div>
                </div>
              </li>
              <li>
                <div class="data-item" @click="homeClickHandle(2)">
                  <div class="menuitem_num">{{ homeMoudle.pendingComplaintNumber }}</div>
                  <div class="menuitem_name">待处理动态投诉</div>
                </div>
              </li>
              <li>
                <div class="data-item" @click="homeClickHandle(3)">
                  <div class="menuitem_num"> {{ homeMoudle.toAuditCircleNumber }}</div>
                  <div class="menuitem_name">待审核圈子</div>
                </div>
              </li>
              <li>
                <div class="data-item" @click="homeClickHandle(4)">
                  <div class="menuitem_num">{{ homeMoudle.toSendOrderNumber }}</div>
                  <div class="menuitem_name">待发货订单</div>
                </div>
              </li>
              <li>
                <div class="data-item" @click="homeClickHandle(5)">
                  <div class="menuitem_num">{{ homeMoudle.toStayOrderNumber }}</div>
                  <div class="menuitem_name">待自提订单</div>
                </div>
              </li>
              <li>
                <div class="data-item" @click="homeClickHandle(6)">
                  <div class="menuitem_num">{{ homeMoudle.waitReplyNumber || 0 }}</div>
                  <div class="menuitem_name">待回复任务</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
import homeManager from '@/request/homeManager.js'
import { getList } from "@/tools/getList.js";
const ConsultationRecordList = () => import( /* webpackChunkName: "seekBeauty" */ '@/components/page/seekBeauty/ConsultationRecordList.vue')
export default {
  mixins: [getList],
  data () {
    return {
      withdrawalAmount: 0,
      homeMoudle: null,
      roleName: '',
      treeArrIdList: []
    }
  },
  computed: {},
  created () {
    // this.handlePermissionDaPing("/home/home");
    // console.log("permission", this.permission);
    // if (this.permission.homeList) {
    // 	this.getHomeList();
    // }
    this.roleName = this.$store.getters.roleName;
    if (this.roleName == '超级用户角色' || this.roleName == '管理员' || this.roleName == '大管家' || this.roleName == '客服') {
      this.$bus.$on("refreshHomeNumData", eventRes => {
        if (eventRes.isRefreshHomeMain) this.getHomeList();
      })
      this.getHomeList()
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.divheight = window.innerHeight - this.$refs.maindiv.offsetTop - 60
      let self = this
      window.onresize = function () {
        console.log()
        if (self.$refs.maindiv) {
          self.divheight =
            window.innerHeight - self.$refs.maindiv.offsetTop - 60
        }
      }
    })
  },
  methods: {
    async getHomeList () {
      try {
        let { code, data } = await homeManager.homeList()
        if (code === 200) {
          this.homeMoudle = data
        }
      } catch (error) {
        console.log(error)
      }
    },
    homeClickHandle (type) {
      var treeArr = this.$store.getters.treeList;
      var array = [];
      treeArr.forEach(obj => {
        array.push(obj.id)
        if (obj.hasChildren) {
          obj.children.forEach(element => {
            array.push(element.id)
          });
        }
      })
      this.treeArrIdList = array
      // console.log('array', array)
      switch (type) {
        case 1:
          // 274
          if (array.includes("274")) {
            this.$router.replace({ path: 'seekBeauty/consultationRecordList' })
          } else {
            this.$message.error("您没有查看用户咨询权限")
          }
          break;
        case 2:
          // 224
          if (array.includes("224")) {
            this.$router.replace({ path: 'circle/newsComplaintList' })
          } else {
            this.$message.error("您没有查看动态投诉权限")
          }
          break;
        case 3:
          // 178
          if (array.includes("178")) {
            this.$router.replace({ path: 'circle/circleList' })
          } else {
            this.$message.error("您没有查看审核圈子权限")
          }
          break;
        case 4:
        case 5:
          // 169
          if (array.includes("169")) {
            this.$router.replace({ path: 'goods/goodsOrderList' })
          } else {
            this.$message.error("您没有查看订单权限")
          }
          break;
        case 6:
          // 316
          if (array.includes("316")) {
            this.$router.replace({ path: '/seekBeauty/taskManagement' })
          } else {
            this.$message.error("您没有查看待回复任务权限")
          }
          break;
        default:
          break;
      }
    },
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
    this.$bus.$off('refreshHomeNumData');
  },

}
</script>
  
<style scoped="scoped">
/* .form-wrap {
	  padding-left: 80px;
  } */
.statisticsContent {
  display: flex;
  flex-direction: column;
}

.el-form-item {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.dataContent {
  width: 90%;
}

.list-content {
  display: block;
  position: relative;
  background-color: white;
  margin-top: 10px;
  width: 100%;
}

.list-content h2 {
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px;
  color: black;
}

.data-list li {
  display: inline-block;
  width: 30%;
  margin: 10px 10px 10px 1%;
}

.data-item {
  width: 100%;
  min-height: 120px;
  background-color: #f7f7f7;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.menuitem_num {
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  font-size: 30px;
  color: #409eff;
}

.menuitem_name {
  padding: 5px 10px 0px 10px;
  font-size: 15px;
  color: #409eff;
}

.leaderboardContent {
  width: 23%;
  margin-top: 10px;
}

.leaderboardContent h2 {
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px;
  color: black;
}

.leaderboardContent ul li {
  width: 100%;
  line-height: 43.1px;
  margin-top: 5px;
  padding: 0px 10px;
  background-color: white;
}
</style>
  