<template>
  <div class="login-bg">
    <div class="login-wrap fl-s-c">
      <div class="login-header">医可达</div>
      <div class="ibox-content">
        <el-form ref="loginForm" :rules="rules" :model="loginForm">
          <el-form-item prop="accountName">
            <el-input size="medium" v-model="loginForm.accountName" prefix-icon="el-icon-user" clearable placeholder="登录名" v-focus tabindex=1 @keyup.enter.native="onSubmit"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input size="medium" v-model="loginForm.password" prefix-icon="el-icon-key" clearable show-password placeholder="登录密码" tabindex=2 @keyup.enter.native="onSubmit"></el-input>
          </el-form-item>
          <el-form-item prop="verifyCode">
            <el-col :span="15">
              <el-input size="medium" v-model="loginForm.verifyCode" prefix-icon="el-icon-lock" clearable placeholder="验证码" tabindex=3 class="clearradius" @keyup.enter.native="onSubmit">
              </el-input>
            </el-col>
            <el-col :span="7">
              <el-image :src="captchadata" style="width:100%;height:40px;vertical-align:middle;object-fill:fill;margin-left:15px;cursor:pointer" @click="getCode">
                <div slot="error" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </el-col>
          </el-form-item>
          <el-form-item style="margin-bottom: 0;">
            <el-button type="primary" size="medium" class="full-width" :loading="loading" :disabled="disabled" @click="onSubmit" tabindex=4>登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>
<script>
import loginManager from '@/request/loginManager.js'
import { baseconfig } from "@/config.js";
export default {
  data () {
    // var validatePass = (rule, value, callback) => {
    // 	if (value === "") {
    // 		callback(new Error("请输入密码"));
    // 	} else {
    // 		// callback();
    // 		var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
    // 		if (regex.test(value)) {
    // 			callback();
    // 		} else {
    // 			callback(new Error("请输入6到20位的数字字母混合密码"));
    // 		}
    // 	}
    // };
    // var validateCallLoginName = (rule, value, callback) => {
    // 	if (this.errors.name) {
    // 		callback(new Error(this.errors.name));
    // 	} else {
    // 		callback()
    // 	}
    // };
    // var validateCallLoginPassword = (rule, value, callback) => {
    // 	if (this.errors.password) {
    // 		callback(new Error(this.errors.password));
    // 	} else {
    // 		callback()
    // 	}
    // };
    // var validateCallCaptchaCode = (rule, value, callback) => {
    // 	if (this.errors.captchacode) {
    // 		callback(new Error(this.errors.captchacode));
    // 	} else {
    // 		callback()
    // 	}
    // };
    return {
      loading: false,
      disabled: false,
      loginForm: {
        accountName: '',
        password: '',
        loginType: 'password',
        verifyCode: ''
      },
      captchadata: `${baseconfig.baseUrl}/getVerify`,
      rules: {
        accountName: [{
          message: "请输入登录名",
          trigger: "blur"
        },
          // {
          // 	validator: validateCallLoginName
          // },
        ],
        password: [{
          // validator: validatePass,
          trigger: 'blur'
        },
          // {
          // 	validator: validateCallLoginPassword
          // },
        ],
        verifyCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        },
          // {
          // 	validator: validateCallCaptchaCode
          // }
        ]
      },
      roleID: 0,
    };
  },
  created () {
    this.getCode()
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus();
      },
    },
  },
  methods: {
    onSubmit () {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.disabled = true
          try {
            let data = await loginManager.adminLogin(this.loginForm);
            if (data.code === 200) {
              this.$store.dispatch('admin/setToken', data.token)
              this.$store.dispatch('admin/setUserName', data.userName)
              this.$store.dispatch('admin/setRoleName', data.roleName)
              // this.$store.commit('admin/SET_USERNAME', data.userName)
              this.$store.commit('admin/SET_ROLESIGN', data.roleSign)

              const backUrl = this.$route.query.backurl;
              if (backUrl) {
                this.$router.push({
                  path: backUrl,
                });
              } else {
                this.$router.push("/");
              }
              this.$bus.$emit('refreshHomeNumData', {
                isRefreshLogin: true,
              });
            } else {
              this.getCode()
            }
          }
          catch (error) {
            console.log(error)
            this.getCode()
          }
          finally {
            this.loading = false;
            this.disabled = false;

          }
        } else {
          return false;
        }
      })
    },
    getCode () {
      this.captchadata = `${baseconfig.baseUrl}/getVerify?id=${Math.random()}`;
      console.log(this.captchadata)
    }


  },
};
</script>
<style scoped>
.login-bg {
  background: url(../../../../assets/img/login-bg.jpg) no-repeat center top
    fixed;
  background-size: cover;
  height: 100%;
}

.login-wrap {
  background-color: rgba(33, 32, 42, 0.44);
  position: relative;
  height: 100%;
  width: 100%;
}

.login-header {
  color: #fff;
  font-size: 34px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: 10%;
}

.ibox-content {
  background-color: #fff;
  padding: 40px 30px 40px 30px;
  clear: both;
  border-radius: 6px;
  width: 320px;
}

.full-width {
  width: 100% !important;
}

.forget-pwd {
  margin-top: -16px;
  font-size: 14px;
  color: #999;
  text-align: right;
}

.forget-pwd span {
  cursor: pointer;
}

.forget-pwd span:hover {
  text-decoration: underline;
}

.box-register {
  font-size: 14px;
  color: #999;
  text-align: center;
}

.box-register span {
  cursor: pointer;
  color: #409eff;
}

.box-register span:hover {
  text-decoration: underline;
}
</style>
