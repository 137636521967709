var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-bg fl-c fr-c" }, [
    _c("div", { staticClass: "lockscreen-wrap fl-c" }, [
      _c(
        "div",
        { staticClass: "ibox-content" },
        [
          _c(
            "el-form",
            {
              ref: "editPwdModel",
              attrs: {
                "label-width": "100px",
                model: _vm.editPwdModel,
                rules: _vm.editPwdRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "pwdNew" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "password",
                      placeholder: "请输入新密码",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.editPwdModel.pwdNew,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPwdModel, "pwdNew", $$v)
                      },
                      expression: "editPwdModel.pwdNew",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "repeat_login_pwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "password",
                      placeholder: "请输入确认密码",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.editPwdModel.repeat_login_pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPwdModel, "repeat_login_pwd", $$v)
                      },
                      expression: "editPwdModel.repeat_login_pwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickEditPwdCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.editPwdLoading },
                  on: { click: _vm.clickEditPwdConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }