var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("v-sidebar"),
      _c(
        "div",
        {
          staticClass: "content-box",
          class: { "content-collapse": _vm.sidebar },
        },
        [
          _c("v-head"),
          _c("v-tags"),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "transition",
                { attrs: { name: "move", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
              _c("el-backtop", { attrs: { target: ".content" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogRealNameAuthVisible
        ? _c(
            "div",
            { staticClass: "lockScreen" },
            [
              _c("RealNameAuth", {
                on: {
                  hide: function ($event) {
                    _vm.dialogRealNameAuthVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }