var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-page" }, [
    _c("div", { staticClass: "error-code" }, [_vm._v("404")]),
    _c("div", { staticClass: "error-desc" }, [
      _vm._v("啊哦~ 你所访问的页面不存在"),
    ]),
    _c(
      "div",
      { staticClass: "error-handle" },
      [
        _c(
          "router-link",
          { staticClass: "error-btn", attrs: { to: "/" } },
          [
            _c("el-button", { attrs: { type: "primary", size: "large" } }, [
              _vm._v("返回首页"),
            ]),
          ],
          1
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "large" },
            on: { click: _vm.goBack },
          },
          [_vm._v("返回上一页")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }