<template>
	<div class="login-bg">
		<div class="lockscreen-wrap fl-c">
			<div class="ibox-content">
				<div class="title">
					<h3>锁屏</h3>
				</div>
				<el-form ref="form" :rules="rules" :model="form">
					<el-form-item prop="password" style="margin-bottom: 70px;">
						<el-input class="code" size="medium" v-model="form.password" clearable show-password placeholder="密码" tabindex="2" @keyup.enter.native="onSubmit"></el-input>
					</el-form-item>
					<el-form-item style="margin-bottom: 30px;">
						<el-button type="primary" size="medium" class="full-width" :loading="showLoading"
							:disabled="disabled" @click="onSubmit" tabindex="4">进入后台</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

	</div>
</template>
<script>
	import loginManager from "@/request/loginManager.js";
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else {
					callback();
					var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/;
					if (regex.test(value)) {
						callback();
					} else {
						callback(new Error("请输入6到20位的数字字母混合密码"));
					}
				}
			};
			return {
				showLoading: false,
				disabled: false,
				form: {
					password: "",
				},
				errors: {
					password: "",
				},
				rules: {
					password: [{
						validator: validatePass,
						trigger: "blur"
					}],
				},
			};
		},
		created() {},
		directives: {
			focus: {
				inserted: function(el) {
					el.querySelector("input").focus();
				},
			},
		},
		methods: {
			onSubmit() {
				console.log("提交")
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						this.showLoading = true;
						this.disabled = true;
						try {
							let params = {
								pwd: this.form.password,
							};
							let {code,result} = await loginManager.lockscreen(params);
							if (code == 200) {
								this.$store.dispatch("app/toggleIsLockScreen",false)
							}
						} finally {
							this.showLoading = false;
							this.disabled = false;
						}
					}
				});
			},
		},
	};
</script>
<style scoped>
	.login-bg {
		background-position: center top;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-image: url(../../../assets/img/login-bg.jpg);
		height: 100%;
	}

	.lockscreen-wrap {
		background-color: rgba(33, 32, 42, 0.44);
		position: relative;
		height: 100%;
		width: 100%;
	}

	.ibox-content {
		background-color: #fff;
		padding: 25px 50px 30px 50px;
		clear: both;
		border-radius: 6px;
		width: 300px;
	}

	.full-width {
		width: 100% !important;
	}
	
	.title {
		width: 100%;
		text-align: center;
		height: 60px;
	}
</style>
