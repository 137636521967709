import BaseModule from './utils/BaseModule'
import store from '@/store'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
  constructor() {
    super()
  }
  //登录
  adminLogin(param) {
	  return this.post('login', param)
	}
	
	//锁屏
	lockscreen(param) {
		return this.post('lockVerify', param)
	}
	
	//退出登录
	logout(param) {
		return this.post('/sys/user/logout', param)
	}
  
}

export default new BaseManager()

